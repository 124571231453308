import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import window from 'global/window'
import Layout from '../components/layout'
import Header from '../components/header'
import Footer from '../components/footer'
import Main from '../components/main'
import Button from '../components/button'
import AppColleagues from '../apps/AppColleagues'
import AppContact from '../apps/AppContact'
// import ArticlePreview from '../components/article-preview'

const mapContent = (oldObj) => {
  let newObj = {
    'it': [],
    'en': []
  };

  for (let p = 0; p < oldObj.length; p++) {
    newObj[oldObj[p].node.node_locale].push(oldObj[p].node);
  }
  
  newObj['it'].sort((a, b) => (a.order > b.order) ? 1 : -1);
  newObj['en'].sort((a, b) => (a.order > b.order) ? 1 : -1);
  
  for (const loc in newObj) {
    for (let n = 0; n < newObj[loc].length; n++) {
      newObj[loc][n].navName = newObj['it'][n].name;
      if (newObj[loc][n].subPages) {
        for (let s = 0; s < newObj[loc][n].subPages.length; s++) {
          newObj[loc][n].subPages[s].navName = newObj['it'][n].subPages[s].name;
        }
      }
    }
  }

  return newObj;
}

const searchSubPage = (pages, navName) => {
  for (const page in pages['it']) {
    if (pages['it'][page].navName === "Servizi") {
      for (const subPage in pages['it'][page].subPages) {
        if (pages['it'][page].subPages[subPage].navName === navName) {
          return true;
        }
      }
    }
  }
  return false;
}

class RootIndex extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
      pages: {},
      contactQuery: get(this, 'props.data.allContentfulContact').edges,
      pagesQuery: get(this, 'props.data.allContentfulPage').edges,
      colleaguesQuery: get(this, 'props.data.allContentfulColleague').edges
    };
  }

  componentWillMount() {
    const { pagesQuery, contactQuery, colleaguesQuery } = this.state;
    // const logo = get(this, 'props.data.allContentfulLogo').edges[0].node
    
    this.setState({
      pages: mapContent(pagesQuery),
      contact: mapContent(contactQuery),
      colleagues: mapContent(colleaguesQuery)
    });
  }

  render() {
    const { pages, contact, colleagues } = this.state;
    const hash = window.location && window.location.hash;

    const route = 
      (hash && hash.substr(1, ((hash.indexOf("?") !== - 1) && (hash.indexOf("?") - 1)) || (hash.length - 1)).replaceAll("%20", " "))
      || "Home";
    
    const locale = 
      (window.location
      && window.location.href.search("language=") !== -1
      && window.location.href.substr(window.location.href.search("language=") + 9, window.location.href.length))
      || "";
    const defaultLocale = 'it';

    let newPage;
    for (let p = 0; p < pages[locale || defaultLocale].length; p++) {
      if (route === pages[locale || defaultLocale][p].navName) {
        newPage = pages[locale || defaultLocale][p];
      }
    }
    
    let newContact = contact[locale || defaultLocale][0];

    return (
      <Layout route={route} pages={pages[locale || defaultLocale]} locale={locale} page={newPage} addresses={newContact.addresses}>
        <Helmet>
          <title>Centro De Graaff</title>
          <meta name="description" content="Studio Centro De Graaff Bardolino Lazise Dentist Italy info@degraaff.it" />
          <meta name="author" content="www.tomruys.com" />
          <meta property="og:title" content="Centro De Graaff" />
          <meta property="og:image" content="http://www.centrodegraaff.com/static/centrodegraafflogo3-32794effd44aedc3fa9c7676d16926d4.jpg" />
          <meta property="og:description" content="Studio Centro De Graaff Bardolino Lazise Dentist Italy info@degraaff.it" />
        </Helmet>
        <Header page={newPage} contact={newContact} locale={locale || defaultLocale} />
        { newPage.app && newPage.app.title === "Colleagues" ?
          <AppColleagues background={newPage.app.background} colleagues={colleagues[locale || defaultLocale]} />
          : null
        }
        <Main>
          {searchSubPage(pages, newPage.navName) ?
            <Button href={"#Servizi" + (locale ? "?language=" + locale : "")}>
              {locale === 'it' ?
                "Torna ai servizi"
              : "Back to services"}
            </Button>
          : null }
        </Main>
        { newPage.app && newPage.app.title === "Contact" ?
          <AppContact addresses={newContact.addresses} locale={locale || defaultLocale} />
        : null }
        <Footer contact={newContact} page={newPage} locale={locale || defaultLocale} />
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulContact {
      edges {
        node {
          node_locale
          title
          addresses {
            title
            street
            tel
            email
            location {
              lat
              lon
            }
            photo {
              sizes(maxWidth: 600, maxHeight: 900) {
               ...GatsbyContentfulSizes_withWebp
              }
            }
            monday
            tuesday
            wednesday
            thursday
            friday
          }
          headerTitle
        }
      }
    }
    allContentfulLogo {
      edges {
        node {
          title
          logo: logo {
            sizes(
              maxWidth: 1500
              maxHeight: 500
            ) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
        }
      }
    }
    allContentfulColleague {
      edges {
        node {
          node_locale
          name
          title
          email
          photo: photo {
            sizes(
              maxWidth: 400
              maxHeight: 400
            ) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
          description {
            json
          }
          order
        }
      }
    }
    allContentfulPage {
      edges {
        node {
          node_locale
          name
          title {
            json
          }
          subTitle {
            json
          }
          showInMenu
          images {
            sizes(maxWidth: 2000, maxHeight: 1000) {
             ...GatsbyContentfulSizes_withWebp
            }
          }
          description {
            json
          }
          subPages {
            name
            title {
              json
            }
            description {
              json
            }
            images {
              sizes(maxWidth: 2000, maxHeight: 1000) {
               ...GatsbyContentfulSizes_withWebp
              }
            }
            announcement
            order
            icon {
              file {
                url
              }
            }
          }
          announcement
          order
          icon {
            file {
              url
            }
          }
          app {
            title
            background {
              sizes(maxWidth: 2000, maxHeight: 1000) {
               ...GatsbyContentfulSizes_withWebp
              }
            }
          }
          headerWidget1 {
            json
          }
          headerWidget2 {
            json
          }
          headerWidget3 {
            json
          }
        }
      }
    }
  }
`
//
// <Hero data={author.node} />
// <div className="wrapper">
//   <h2 className="section-headline">Recent articles</h2>
//   <ul className="article-list">
//     {posts.map(({ node }) => {
//       return (
//         <li key={node.slug}>
//           <ArticlePreview article={node} />
//         </li>
//       )
//     })}
//   </ul>
// </div>
