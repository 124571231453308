import React from 'react'
import Img from 'gatsby-image'
import styles from './contact.module.css'

export default ({ addresses, locale }) => {
console.log(addresses)
  return (
    <article>
			{addresses.map(({ title, street, tel, email, location, photo, monday, tuesday, wednesday, thursday, friday }) => (
				<div key={title} className={styles.addressHolder}>
					<section key={title} className={styles.address}>
						<address className={styles.addressDetails}>
							<h2>Studio {title}</h2>
							<p><a target="_blank" href={`https://maps.google.com/?q=${location.lat} ${location.lon}`}>{street}</a></p>
							<p>tel: <a href={`tel:${tel}`}>{tel}</a></p>
							<a href={"mailto:" + email + "?subject=Centro De Graaff" + (locale === 'en' ? " question" : " domanda")}>{email}</a>
							<div className={styles.openinghours}>
								<em>{locale === 'it' ? "orari" : "opens"}</em>
								<div className={styles.days}>
									<span><span>{locale === 'it' ? "Lunedì" : "Monday"}</span> <span>{ monday }</span></span>
									<span><span>{locale === 'it' ? "Martedì" : "Tuesday"}</span> <span>{ tuesday }</span></span>
									<span><span>{locale === 'it' ? "Mercoledì" : "Wednesday"}</span> <span>{ wednesday }</span></span>
									<span><span>{locale === 'it' ? "Giovedì" : "Thursday"}</span> <span>{ thursday }</span></span>
									<span><span>{locale === 'it' ? "Venerdì" : "Friday"}</span> <span>{ friday }</span></span>
								</div>
							</div>
						</address>
						<div className={styles.mapsHolder}>
							<iframe title={`${title} location address`} width="375" height="300" loading="lazy" allowfullscreen src={`https://www.google.com/maps/embed/v1/search?q=${location.lat}%20${location.lon}&key=AIzaSyDX5V6Q__HCpvHCwda9EjQqulVlQsPJPUo`}></iframe>
							<Img className={styles.photo} alt={title} fixed={photo.sizes} />
						</div>
					</section>
				</div>
			))}
    </article>
  )
}
// Studio Bardolino

// Via Croce, 35
// tel 045 7211011
// info@centrodegraaff.com
// opens
// Monday 08:00 - 13:00 / 15:00 - 17:00
// Tuesday 08:30 - 12:00 / 15:00 - 19:00
// Wednesday 09:00 - 12:00 / 15:00 - 19:00
// Thursday 09:00 - 12:00
// Friday 08:00 - 13:00 / 15:00 - 17:00



// Studio Lazise

// Via A. della Scala, 72
// tel 045 6470213
// info@centrodegraaff.com
// opens
// Monday 08:00 - 13:00 / 15:00 - 17:00
// Tuesday 08:30 - 12:00 / 15:00 - 19:00
// Wednesday 09:00 - 12:00 / 15:00 - 19:00
// Thursday 09:00 - 12:00
// Friday 08:00 - 13:00 / 15:00 - 17:00