import React from 'react'
import styles from './button.module.css'

export default ({ children, href }) => {
  const TagName = href ? "a" : "button";
  return (
    <TagName className={styles.button} href={href}>
      {children}
    </TagName>
  )
}
