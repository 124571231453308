import React, { useState } from 'react'
import Img from 'gatsby-image'
import styles from './selections.module.css'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import cx from 'classnames'

export default ({ background, colleagues }) => {
	const [selectedColleague, setActive] = useState(undefined);

  return (
    <section className={cx(styles.wrapper, styles.colleagues)}>
		<div className={styles.backgroundWrapper}>
			<img className={styles.background} src={background.sizes.src} alt="background" />
		</div>

		<ul className={styles.selections}>
			{ colleagues.map(({ name, photo, title, email, description }, index) => (
				<button key={index} className={styles.selection} onClick={() => setActive({ name, photo, title, description })} onKeyPress={() => setActive({ name, photo, title, description })}>
					<Img className={styles.image} fluid={photo.sizes} alt={name} />
					<div className={styles.content}>
						<h6 className={styles.name}>{name}</h6>
						<h6 className={styles.title}>{title}</h6>
					</div>
				</button>
			))}
		</ul>

		{ selectedColleague ?
			<div className={styles.modal}>
				<button className={styles.modalButton} onClick={() => setActive(undefined)} onKeyPress={() => setActive(undefined)}>
					X
				</button>
				<article className={styles.activeSelection}>
					<Img className={styles.activeSelectionImg} fluid={selectedColleague.photo.sizes} alt={selectedColleague.name} />
					<section className={styles.activeSelectionContent}>
						<h5>{selectedColleague.title} {selectedColleague.name}</h5>
						<p dangerouslySetInnerHTML={{__html: documentToHtmlString(selectedColleague.description.json) }} />
					</section>
				</article>
			</div>
		: null }
    </section>
  )
}
