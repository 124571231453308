import React from 'react'
import styles from './main.module.css'

export default ({ children }) => {
  return (
    <main className={children && styles.main}>
      <section className={children && styles.content}>
        {children}
      </section>
    </main>
  )
}
